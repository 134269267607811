
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      'Inicio.categoria1': 'Home',
      'Inicio.categoria2': 'About Us',
      'Inicio.categoria3': 'Subscription',
      'Inicio.categoria4': 'Products',
      'Inicio.categoria5': 'Testimony',
      'Inicio.categoria6': 'Contacts',
      'Inicio.Eslogan': 'Operating for more than 25 years, your best technology partner.',
      'acercaDe.titulo': 'About Us',
      'acercaDe.contenido': 'It is a technology company founded in 1995 for regional service.\nWe manage a client portfolio of more than 1000 companies located in various countries, from the United States to Argentina. Our products in fields related to engineering are considered, and recognized for their robustness and quality at the discretion of our clients both in THE Software and in the system hardware. We make sure that 100% of our products developed by our company meet the highest standards international standards that are imposed on us, we manage ISO standards both in processes operations of the company, such as in the production of our products.',
      'acercaDe.tituloUb': 'Location Details',
      'acercaDe.contenidoUb1': 'Between Av. Eloy Alfaro and Av. 6 de Diciembre',
      'acercaDe.contenidoUb2': 'Czechosloquia Street E10195',
      'acercaDe.contenidoUb3': 'Cuarzo Building - Office 306',
      'acercaDe.tituloH': 'Schedule',
      'acercaDe.datoH1': 'From Monday to Thursday',
      'acercaDe.datoH2': '9:00am to 10:00am',
      'acercaDe.datoH3': 'From Friday to Sunday',
      'acercaDe.datoH4': '11:00am to 12:00pm',
      'subcripcion.dato1': 'Electronic Billing',
      'subcripcion.dato2': 'Integrated risk system.',
      'subcripcion.dato3': 'Electronic Receipt System.',
      'subcripcion.dato4': 'Subscribe our newsletter.',
      'productos.comentario': 'check out some of our works',
      'productos.titulo1': 'Measurement Software',
      'productos.titulo2': 'Risk consulting',
      'productos.titulo3': 'Engineering',
      'productos.titulo4': 'BIM system',
      'productos.descripcion1': 'We have more than 500 products.',
      'productos.descripcion2': 'With ISO certified professionals.',
      'productos.descripcion3': 'We do integration engineering.',
      'productos.descripcion4': 'Experts in developing modeling.',
      'productos.descripcion5': 'Creation of solid solutions.',
      'testimonio.comentario': 'Products and services aimed at seeking customer satisfaction.',
      'testimonio.autor': '¬ Tecservin Team',

    },
  },
  es: {
    translation: {
        'Inicio.categoria1': 'Inicio',
        'Inicio.categoria2': 'Acerca de',
        'Inicio.categoria3': 'Subcripción',
        'Inicio.categoria4': 'Productos',
        'Inicio.categoria5': 'Testimonio',
        'Inicio.categoria6': 'Contactos',
        'Inicio.Eslogan': 'Funcionando por más de 25 años, tu mejor socio tecnológico.',
        'acercaDe.titulo': 'Acerca de',
        'acercaDe.contenido': 'Es una empresa de tecnologia fundada en el año de 1995 para el servicio regional.\nManejamos una cartera de clientes de más de 1000 empresas ubicadas en diversos países, desde Estados Unidos hasta Argentina. Nuestro productos en campos relacionados a la ingenieria son considerados, y reconocidos por su robustez y calidad a criterio de nuestros clientes tanto en EL Software como en el Hardware del sistema. Nos cersioramos que el 100% de nuestros productos desarrollados por nuestra empresa cumplan los mas altos estandares internacionales que se nos impongan, manejamos normas ISO tanto en procesos operativos de la empresa, como en la elaboración de nuestros productos.',
        'acercaDe.tituloUb': 'Detalles de la Ubicación',
        'acercaDe.contenidoUb1': 'Entre Av. Eloy Alfaro y Av. 6 de Diciembre',
        'acercaDe.contenidoUb2': 'Calle Checosloquia E10195',
        'acercaDe.contenidoUb3': 'Edificio Cuarzo - Oficina 306',
        'acercaDe.tituloH': 'Horario',
        'acercaDe.datoH1': 'De lunes a jueves',
        'acercaDe.datoH2': '9:00am a 10:00am',
        'acercaDe.datoH3': 'De viernes a domingo',
        'acercaDe.datoH4': '11:00am a 12:00pm',
        'subcripcion.dato1': 'Facturación Electrónica',
        'subcripcion.dato2': 'Sistema integrado de riesgos.',
        'subcripcion.dato3': 'Sistema de Comprobantes Electrónicos.',
        'subcripcion.dato4': 'Suscríbete a nuestro boletín.',
        'productos.comentario': 'mira algunos de nuestros trabajos',
        'productos.titulo1': 'Software a Medida',
        'productos.titulo2': 'Consultoria de riesgos',
        'productos.titulo3': 'Ingeniería',
        'productos.titulo4': 'Sistema BIM',
        'productos.descripcion1': 'Contamos con mas de 500 productos.',
        'productos.descripcion2': 'Con profesionales certificados en ISO.',
        'productos.descripcion3': 'Hacemos Ingeniería de integración.',
        'productos.descripcion4': 'Expertos en elaborar el modelado.',
        'productos.descripcion5': 'Creación de soluciones sólidas.',
        'testimonio.comentario': 'Productos y servicios orientados a la buscaqueda de la satisfacción del cliente.',
        'testimonio.autor': '¬ Equipo Tecservin',
  

    },
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'es',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
