import React from "react";
import { useTranslation } from "react-i18next";
import '../estilos/Testimonio.css'
 

function Testimonio (){
  const { t } = useTranslation();
    return(
      <section id="Testimony"> 
        <div className="container">
          <div className="row flex-column">
            <div className="col-md-6 col-12" style={{ marginBottom: '-90px'}}>
              <div className="contenedor-comillas">
                <p>❝</p>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="contenedor-testimonio">
                <p>{t('testimonio.comentario')}</p>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="contenedor-autor">
                <p style={{ fontSize: '1.3rem', position: 'relative', paddingLeft: '20px' }}>{t('testimonio.autor')}</p>
              </div>
            </div>
          </div>
        </div>
      </section>


    );
}

export default Testimonio;