
import './App.css';
import Inicio from './componentes/Inicio';
import AcercaDe from './componentes/AcercaDe';
import Subcripcion from './componentes/Subcripcion';
import Productos from './componentes/Productos';
import Testimonio from './componentes/Testimonio';
import Contactos from './componentes/Contactos';
import i18n from './traductor/Traduccion';

function App() {
  return (
    <div className="App">
      <Inicio/>
      <AcercaDe/>
      <Subcripcion/>
      <Productos/>
      <Testimonio/>
      <Contactos/>
    </div>
  );
}

export default App;
