import React from "react";
import { useTranslation } from "react-i18next";
import '../estilos/AcercaDe.css'

function AcercaDe() {
  const { t} = useTranslation();
    return(
      <section id="AboutUs">
        <div class="container">
          <div class="row">
            <aside class="col-md-3 custom-aside d-flex flex-column align-items-center justify-content-center position-relative" style={{ marginTop: '20px' }}>
              <h1 style={{ marginBottom: '40px' }}>{t('acercaDe.tituloH')}</h1>
              <h4>{t('acercaDe.datoH1')}</h4>
              <p>{t('acercaDe.datoH2')}</p>
              <h4>{t('acercaDe.datoH3')}</h4>
              <p>{t('acercaDe.datoH4')}</p>
              <div class="aside-linea"></div>
            </aside>
            <div class="col-md-6 ml-auto" id="infAcerca" style={{ marginTop: '20px' }}>
              <h1 style={{ marginBottom: '25px', fontSize: '2.2em' }}>Acerca de</h1>
              <p style={{ fontSize: '1em' }} >{t('acercaDe.contenido').split('\n').map((line, index, array) => (
              <React.Fragment key={index}>
                <p>{line}</p>
                {index < array.length - 1 && <div style={{ height: '0px' }}></div>}
              </React.Fragment>
            ))}</p>
            </div>
            <div class="col-md-6 ml-auto" id="infUbicacion" style={{ marginTop: '10px' }}>
              <h1 style={{ marginBottom: '25px', fontSize: '2.2em'}}>{t('acercaDe.tituloUb')}</h1>
              <ul>
                <li style={{ marginBottom: '20px', fontSize: '1em' }}>{t('acercaDe.contenidoUb1')}</li>
                <li style={{ marginBottom: '20px', fontSize: '1em' }}>{t('acercaDe.contenidoUb2')}</li>
                <li style={{ marginBottom: '20px', fontSize: '1em' }}>{t('acercaDe.contenidoUb3')}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
}

export default AcercaDe;