import React from "react";
import { useTranslation } from "react-i18next";
import '../estilos/Productos.css'


function Productos() {
  const { t } = useTranslation();
    return(
      <section id="Products" className="text-center position-relative">
        <div className="container vh-100" style={{ padding: '20px' }}>
          <h2 id="Titulo" className="mt-5" style={{ fontSize: '1.1rem', position: 'relative', top: '50px', left: '-0.5%' }}>
          {t('productos.comentario')}
            <span style={{ position: 'absolute', content: '', height: '3px', width: '40px', background: '#999', marginLeft: '-420px', marginTop: '8px' }}></span>
            <span style={{ position: 'absolute', content: '', height: '3px', width: '40px', background: '#999', marginLeft: '15px', marginTop: '8px' }}></span>
          </h2>
          <div className="productos row justify-content-center" style={{ marginTop: '100px' }}>
            <div className="col-12 col-md-6 col-lg-4 m-2 mb-4 imagen-contenedor">
              <img src={require("../imagenes/Software.jpg")} alt="Software" className="img-fluid" style={{  borderRadius: '10px' }} />
              <p>{t('productos.descripcion1')}</p>
              <div className="Titulos">
                <h3>{t('productos.titulo1')}</h3>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 m-2 mb-4 imagen-contenedor">
              <img src={require("../imagenes/Riesgos.png")} alt="Riesgos" className="img-fluid" style={{  borderRadius: '10px' }} />
              <p>{t('productos.descripcion2')}</p>
              <div className="Titulos">
                <h3>{t('productos.titulo2')}</h3>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 m-2 mb-4 imagen-contenedor" >
              <img src={require("../imagenes/Ingenieria.jpg")} alt="Ingenieria" className="img-fluid" style={{  borderRadius: '10px' }} />
              <p>{t('productos.descripcion3')}</p>
              <div className="Titulos">
                <h3>{t('productos.titulo3')}</h3>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 m-2 mb-4 imagen-contenedor">
              <img src={require("../imagenes/BIM.png")} alt="BIM" className="img-fluid" style={{borderRadius: '10px' }} />
              <p>{t('productos.descripcion4')}</p>
              <div className="Titulos">
                <h3>{t('productos.titulo4')}</h3>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 m-2 mb-4 imagen-contenedor">
              <img src={require("../imagenes/SIG.png")} alt="SIG" className="img-fluid" style={{  borderRadius: '10px' }} />
              <p style={{marginBottom: '-100px'}}>{t('productos.descripcion5')}</p>
              <div className="Titulos">
                <h3>SIG</h3>
              </div>
            </div>
          </div>
        </div>
      </section>

    

    );
}

export default Productos;