import React from "react";
import '../estilos/Subcripcion.css'
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faListCheck } from '@fortawesome/free-solid-svg-icons';



function Subcripcion() {
  const { t} = useTranslation();
    return (
      <section id="Subscription">
        <div class="container text-center" >
          <div class="row">
            <div class="mr-3 contenedorInf" >
              <FontAwesomeIcon icon={faListCheck} style={{ fontSize: '3em', marginBottom: '20px' }}/>
              <h3 style={{ marginBottom: '20px' }}>Sisprev</h3>
              <p>{t('subcripcion.dato2')}</p>
            </div>
            <div class="mr-3 contenedorInf" >
              <FontAwesomeIcon icon={faFile} style={{ fontSize: '3em', marginBottom: '20px' }}/>
              <h3  style={{ marginBottom: '20px' }}>{t('subcripcion.dato1')}</h3>
              <p>{t('subcripcion.dato3')}</p>
            </div>
          </div>
            <div className="container Subcripcion">
              <div className="row justify-content-center">
                <div className="col-12 col-md-10 col-lg-8">
                  <div className="Marco">
                  <h2 className="position-absolute top-10 start-50 translate-middle-x text-center fw-bolder" style={{ fontSize: '2.2rem' }}>{t('subcripcion.dato4')}</h2>
                    <ul className="list-unstyled d-flex p-0">
                      <li><img src={require("../imagenes/Banco_Guayaquil.png")} alt="BancoGuayaquil"/></li>
                      <li><img src={require("../imagenes/pichincha.png")} alt="BancoPichincha"/></li>
                      <li><img src={require("../imagenes/banco_mundial.png")} alt="BancoMundial"/></li>
                      <li><img src={require("../imagenes/logo_coca.jpeg")} alt="CocaCola"/></li>
                      <li><img src={require("../imagenes/Crepes.png")} alt="Crepes&Waffles"/></li>
                      <li><img src={require("../imagenes/Digitec.jpg")} alt="Digitec"/></li>
                      <li><img src={require("../imagenes/EcuaRe.jpg")} alt="EcuaRe"/></li>
                      <li><img src={require("../imagenes/Hispana.png")} alt="Hispana"/></li>
                      <li><img src={require("../imagenes/Generali.jpeg")} alt="Generali"/></li>
                      <li><img src={require("../imagenes/EEQ.png")} alt="EmpresaElectrica"/></li>
                      <li><img src={require("../imagenes/Latina.jpeg")} alt="Latina"/></li>
                      <li><img src={require("../imagenes/CentroSeguros.jpg")} alt="CentroSeguros"/></li>
                      <li><img src={require("../imagenes/LLS.png")} alt="LLSseguros"/></li>
                      <li><img src={require("../imagenes/endesa.png")} alt="Endesa"/></li>
                      <li><img src={require("../imagenes/equinoccial.png")} alt="Equinoccial"/></li>
                      <li><img src={require("../imagenes/LaUnion.png")} alt="LaUnion"/></li>
                      <li><img src={require("../imagenes/Balboa.jpeg")} alt="BalboaSeguros"/></li>
                      <li><img src={require("../imagenes/SolidarioConmigo.png")} alt="Solidario"/></li>
                      <li><img src={require("../imagenes/Condor.jpg")} alt="Condor"/></li>
                      <li><img src={require("../imagenes/tecniseguros.jpg")} alt="TecniSeguros"/></li>
                      <li><img src={require("../imagenes/Unicef.png")} alt="Unicef"/></li>
                      <li><img src={require("../imagenes/MCV.jpeg")} alt="MCV"/></li>
                      <li><img src={require("../imagenes/Unibanco.jpeg")} alt="UniBanco"/></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
         </div>
      </section>
    );
}

export default Subcripcion;