import React from "react";
import '../estilos/Contactos.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faGoogle, faLinkedin, faSkype, faXTwitter } from "@fortawesome/free-brands-svg-icons";


function Contactos() {
  
    return(
      <section id="Contacts" className="d-flex align-items-center">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-4 col-sm-12 contenedor-info d-flex align-items-center">
              <FontAwesomeIcon icon={faPhone} style={{  marginRight: '30px' }} className="iconoContacto"/>
              <div>
                <p className="mb-0">+593-3324 444</p>
                <p>+1-123 456 7891</p>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 contenedor-boton d-flex justify-content-center align-items-center">
              <a href="#Home"><button className="btn btn-primary rounded-circle click-animacion" style={{ fontSize: '3rem', backgroundColor: '#928484' }}><FontAwesomeIcon icon={faChevronUp}/></button></a>
            </div>
            <div className="col-md-4 col-sm-12 contenedor-iconos">
              <p style={{ fontSize: '2.8rem', marginBottom: '50px' }}>Quito</p>
              <ul className="d-flex list-unstyled"> 
                <li><a href="https://www.facebook.com/"><FontAwesomeIcon icon={faFacebook} className="iconos" style={{ marginRight: '10px' }} /></a></li>
                <li><a href="https://twitter.com/?lang=es"><FontAwesomeIcon icon={faXTwitter} className="iconos" style={{ marginRight: '10px' }} /></a></li>
                <li><a href="https://www.google.com/intl/es/gmail/about/"><FontAwesomeIcon icon={faGoogle} className="iconos" style={{ marginRight: '10px' }} /></a></li>
                <li><a href="https://ec.linkedin.com/"><FontAwesomeIcon icon={faLinkedin} className="iconos" style={{ marginRight: '10px' }} /></a></li>
                <li><a href="https://web.skype.com/"><FontAwesomeIcon icon={faSkype} className="iconos" style={{ marginRight: '10px' }} /></a></li>
              </ul>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-6 col-sm-12 contenedor-descripcion text-center" style={{ marginTop: '20px' }}> 
              <p>@ Copyright 2023 Powered by Tecservin.com</p>
            </div>
          </div>
        </div>
      </section>
    

    
    );
}

export default Contactos;