import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faGoogle, faLinkedin, faSkype, faXTwitter } from "@fortawesome/free-brands-svg-icons"

import '../estilos/Inicio.css'

function Inicio() {
  const { t, i18n } = useTranslation();

  const cambiarIdioma = (idioma) => {
    i18n.changeLanguage(idioma);
  };
  return (
    <section id="Home">
      <nav class="navbar navbar-expand-lg navbar-dark bg-dark w-100">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-center" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item ">
              <a class="nav-link" href="#Home">{t('Inicio.categoria1')}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#AboutUs">{t('Inicio.categoria2')}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#Subscription">{t('Inicio.categoria3')}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#Products">{t('Inicio.categoria4')}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#Testimony">{t('Inicio.categoria5')}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#Contacts">{t('Inicio.categoria6')}</a>
            </li>
            <li class="nav-item">
              <button class="btn btn-outline-light" onClick={() => cambiarIdioma('en')}>
                <img src={require("../imagenes/en.jpeg")} alt="Imagen 1" class="img-fluid" id="Lenguaje"/>
              </button>
              <button class="btn btn-outline-light" onClick={() => cambiarIdioma('es')}>
                <img src={require("../imagenes/es.png")} alt="Imagen 2" class="img-fluid"  id="Lenguaje"/>
              </button>
            </li>
          </ul>
        </div>
      </nav>
      <div class="container mt-3" id="contenedorLogo" style={{  marginBottom: '40px' }}>
        <img src={require("../imagenes/Tecservin.png")} alt="Tecservin" class="img-fluid" id="Logo"/>
      </div>
      <div class="container mt-3 d-flex flex-column align-items-center" id="contenedorEslogan" style={{  padding: '0px', border: '3px solid #000', borderRadius: '15px', boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.5)' }}>
        <p class="lead text-center" style={{ fontSize: '1.6em', marginBottom: '20px' }}>{t('Inicio.Eslogan')}</p>
        <ul class="list-unstyled d-flex justify-content-center">
          <li class="mx-3" id="icon-link">
            <a href="https://www.facebook.com/" >
              <FontAwesomeIcon icon={faFacebook} style={{ fontSize: '2.1em' }} />
            </a>
          </li>
          <li class="mx-3" id="icon-link">
            <a href="https://twitter.com/?lang=es" >
              <FontAwesomeIcon icon={faXTwitter} style={{ fontSize: '2.1em' }} />
            </a>
          </li>
          <li class="mx-3" id="icon-link">
            <a href="https://www.google.com/intl/es/gmail/about/" >
              <FontAwesomeIcon icon={faGoogle} style={{ fontSize: '2.1em' }} />
            </a>
          </li>
          <li class="mx-3" id="icon-link">
            <a href="https://ec.linkedin.com/" >
              <FontAwesomeIcon icon={faLinkedin} style={{ fontSize: '2.1em' }} />
            </a>
          </li>
          <li class="mx-3" id="icon-link">
            <a href="https://web.skype.com/" >
              <FontAwesomeIcon icon={faSkype} style={{ fontSize: '2.1em' }} />
            </a>
          </li>
        </ul>
        
      </div>
    </section>
  );
}

export default Inicio;